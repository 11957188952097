import React, { useState } from "react";
import "./style.css";
import * as authUtil from "../../utils/auth.util";
import * as userUtil from "../../utils/user.util";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

import { ApiPost } from "../../helpers/API/ApiData";
import {
  Spinner,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardText,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import Sidebar from "../Home/Sidebar";
export default function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [errors, setError] = useState({});
  const [modal, setModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const toggle = (e) => {
    setModal(!modal);
  };
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!email) {
      formIsValid = false;
      errors["email"] = "*Please Enter email";
    }
    if (!password) {
      formIsValid = false;
      errors["password"] = "*Please Enter password";
    }

    setError(errors);
    return formIsValid;
  };
  const submitHandler = () => {
    if (validateForm()) {
      setIsLoader(true);
      const data = {
        email: email,
        password: password,
      };
      ApiPost("users/validateAccount", data)
        .then(async (res) => {
          await authUtil.setToken(res.data.data[0].verifyToken);
          await userUtil.setUserInfo(res.data.data);

          setIsLoader(false);

          history.push("/dashboard");
          toast.success("Login Successfully");
          // onSubmit(values);
          // reset();
        })
        .catch(
          (err) => toast.error("Please enter valid email and password"),
          setIsLoader(false)
        );
    }
  };
  const history = useHistory();
  return (
    <div>
      <ToastContainer />
      <Row className='m-0' style={{ paddingTop: "110px" }}>
        <Col md={4}></Col>
        <Col md={4}>
          <Card body className='text-center'>
            <CardTitle>
              {" "}
              <img
                src={require("../../../src/Images/L.png")}
                alt='Mark48'
                className='react-rainbow-admin-forms_logo img-fluid'
              />
            </CardTitle>
            <CardText>
              <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
                <Row>
                  <Col md={12}>
                    <Label for='exampleEmail' className='mr-sm-2'>
                      Email<span style={{ color: "red" }}> * </span>
                    </Label>
                    <Input
                      type='email'
                      name='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id='exampleEmail'
                      placeholder='enter your email'
                    />
                    <span
                      style={{
                        color: "red",

                        top: "5px",
                        fontSize: "10px",
                      }}
                    >
                      {errors["email"]}
                    </span>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
                <Row>
                  <Col md={12}>
                    <Label for='examplePassword' className='mr-sm-2'>
                      Password<span style={{ color: "red" }}> * </span>
                    </Label>
                    <Input
                      type='password'
                      name='password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      id='examplePassword'
                      placeholder='********'
                    />
                    <span
                      style={{
                        color: "red",

                        top: "5px",
                        fontSize: "10px",
                      }}
                    >
                      {errors["password"]}
                    </span>
                  </Col>
                </Row>
              </FormGroup>
            </CardText>
            {isLoader === true ? (
              <Spinner />
            ) : (
              <Button color='primary' onClick={() => submitHandler()}>
                Login
              </Button>
            )}
          </Card>
        </Col>
        <Col md={4}></Col>
      </Row>
    </div>
  );
}
