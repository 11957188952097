import React, { Component } from "react";
import "./table.css";
import "react-table/react-table.css";

import { Router, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// import { renderRoutes } from 'react-router-config';
import history from "../history";
import Login from "../components/SignInPage/LoginPage";
import ContactUs from "../components/Home/ContactUs";
import Dispatching from "../components/Home/Dispatching";
import PrivacyPolicy from "../components/Home/PrivacyPolicy";
const loading = () => (
  <div className='animated fadeIn pt-3 text-center'>Loading...</div>
);

// Routing
// Containers
const DefaultLayout = React.lazy(() =>
  import("../Containers/DefaultLayout/index")
);
const SignIn = React.lazy(() => import("../components/SignInPage/SignInPage"));

const ProtectedRoute = React.lazy(() => import("../ProtectedRoute"));
class App extends Component {
  render() {
    return (
      <Router history={history}>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Redirect from='/' exact to='/dashboard' />
            <Route
              path='/signin/contactus'
              name='contactus'
              component={ContactUs}
            />
            <Route
              path='/signin/dispatching'
              name='Dispatching'
              component={Dispatching}
            />
            <Route
              path='/privacy-policy'
              name='PirvacyPolicy'
              component={PrivacyPolicy}
            />
            <Route path='/signin' name='Login Page' component={SignIn} />
            <Route path='/login' name='Login' component={Login} />
            <ProtectedRoute path='/dashboard' component={DefaultLayout} />
            <ProtectedRoute path='/' component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;
