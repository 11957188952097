const protocol = "https";
const host = "localhost:6001/api/";
const port = "";
const trailUrl = "";
// const protocol = "https";
// const host = "nemtdispatchapi.rejoicehub.com/api";
const hostUrl = `${protocol}://${host}${port ? ":" + port : ""}/`;
const endpoint = `${protocol}://${host}${port ? ":" + port : ""}${trailUrl}`;

export default {
  protocol: protocol,
  host: host,
  port: port,
  apiUrl: trailUrl,
  endpoint: endpoint,
  hostUrl: hostUrl,
};
