import React, { useState } from "react";
import "../SignInPage/style.css";
import { NavLink } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import Sidebar from "../Home/Sidebar";
export default function Dispatching(props) {
  console.log("Local Storage");
  console.log(localStorage);
  for (let i = 0; i < localStorage.length; i++) {
    let storedValue = localStorage.key(i);
    console.log(`Item at ${i}: ${storedValue}`);
  }
  return (
    <div>
      <Sidebar />
      <main className='s-layout__content'>
        <div style={{ width: "100%" }}>
          <section>
            <div className='contact-banner'>
              <div className='d-flex align-items-center'>
                <div>
                  <div className='contact-text'>
                    <h1 className='text-center'>About Ussada</h1>
                    <p className='text-center'>
                      Our dispatching software was created by people that
                      dispatch NEMT
                      <br /> drivers and vehicles every single day.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='pt-5'>
            <div className='container-fluid'>
              <div className='row pt-3'>
                <div className='col-md-12'>
                  <div className='icon-img d-flex justify-content-center'>
                    <img src={require("../../Images/icon.png")} />
                  </div>
                  <div className='child-heading'>
                    <h1 className='mt-2'>
                      <span>NEMT Consultants </span>is the most robust in the
                      industry.
                    </h1>
                    <div className='border-top-cus'></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='pt-5'>
            <div className='our-story'>
              <div className=' w-100 container-fluid'>
                <div className='our-story-text'>
                  <h1 className='text-white text-center'>Our Story</h1>
                  <div className='border-top-cus white-border'></div>
                  <p className='text-white text-center'>
                    Exponentially increase your efficiency
                  </p>
                </div>
                <div className='row pt-5'>
                  <div className='col-lg-3 col-md-6 text-center'>
                    <div className=''>
                      <div className=''>
                        <div className='round-icon'>
                          <i class='fas fa-peace'></i>
                        </div>
                        <p className='text-white font-size-24 margin-bottom pt-2'>
                          Pair
                        </p>
                        <p className='text-white font-size-16'>
                          with the right drivers
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 text-center'>
                    <div className=''>
                      <div className=''>
                        <div className='round-icon'>
                          <i class='fas fa-car'></i>
                        </div>
                        <p className='text-white font-size-24 margin-bottom pt-2'>
                          Right
                        </p>
                        <p className='text-white font-size-16'>
                          with the right drivers
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 text-center'>
                    <div className=''>
                      <div className=''>
                        <div className='round-icon'>
                          <i class='fas fa-cogs'></i>
                        </div>
                        <p className='text-white font-size-24 margin-bottom pt-2'>
                          Efficient
                        </p>
                        <p className='text-white font-size-16'>
                          with the right drivers
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 text-center'>
                    <div className=''>
                      <div className=''>
                        <div className='round-icon'>
                          <i class='fas fa-map-marker'></i>
                        </div>
                        <p className='text-white font-size-24 margin-bottom pt-2'>
                          Make
                        </p>
                        <p className='text-white font-size-16'>
                          with the right drivers
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='pt-5'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='d-flex justify-content-center text-center'>
                    <div className='icon3'>
                      <img src={require("../../Images/icon3.png")} />
                      <h1>Our software has no equal in the industry.</h1>
                      <div className='border-top-cus'></div>
                      <p>
                        Our price point is less expensive than the “big guys”
                      </p>
                      <h6>but the end product eclipses their results.</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='pt-5'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='d-flex justify-content-center text-center'>
                    <div className='icon3'>
                      <img src={require("../../Images/icon3.png")} />
                      <h1>Our product is easier and more productive.</h1>
                      <div className='border-top-cus'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='pt-3 '>
            <div className='west-banner'>
              <div className='container-fluid'>
                <div className='row pt-5'>
                  <div className='col-md-12'>
                    <div className='scoot-text'>
                      <div className='child-heading'>
                        <p>
                          <b>Text message communications.</b> We use text
                          messaging to communicate with you about your service.
                          Normal messaging rates apply and the frequency of
                          messages may vary. Mobile Carriers are not liable for
                          delayed or undelivered messages. No mobile information
                          will be shared with third parties/affiliates for
                          marketing/promotional purposes. All other categories
                          exclude text messaging originator opt-in data and
                          consent; this information will not be shared with any
                          third parties.
                        </p>
                        <p>
                          <b>Opt-out of text message communications.</b> You may
                          opt out of text messaging at any time by replying to
                          any message with STOP or contacting us at
                          info@nemtsolutions.com This will end the
                          communications from that particular phone number. You
                          may continue to receive service-related and other
                          non-marketing text messages from other phone numbers
                          managed by NEMT Solutions, and you may opt out of
                          those in a similar fashion.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className=''>
            <div className='car-banner'>
              <div className='d-flex justify-content-center align-items-center'>
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='heading1-text'>
                        <h1>Already a client?</h1>
                        <div className='pt-2 d-flex justify-content-center'>
                          <NavLink to='/login'>
                            <button className='learn-more-btn-style text-center'>
                              <i className='fa-plus fas pr-2'></i>
                              Click Here to Login
                            </button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='pt-5'>
            <div className='container-fluid'>
              <div className='copy-right'>
                <p>
                  <span> © Copyright 2012 - 2024 </span>
                  <span className='pl-2'>| NEMT Solutions </span>
                  <span className='blue-color pl-2'>| All Rights Reserved</span>
                </p>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
