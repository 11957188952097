import React, { useState } from "react";
import "../SignInPage/style.css";

import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ApiPost } from "../../helpers/API/ApiData";
import { Input, FormGroup } from "reactstrap";
import Sidebar from "../Home/Sidebar";
export default function ContactUs(props) {
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setError] = useState({});
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!email) {
      formIsValid = false;
      errors["email"] = "*Please Enter email";
    }
    if (!name) {
      formIsValid = false;
      errors["name"] = "*Please Enter name";
    }
    if (!message) {
      formIsValid = false;
      errors["message"] = "*Please Enter message";
    }
    setError(errors);
    return formIsValid;
  };
  const submitHandler = () => {
    if (validateForm()) {
      const data = {
        email: email,
        name: name,
        message: message,
      };
      ApiPost("contactus", data)
        .then(async (res) => {
          setName("");
          setEmail("");
          setMessage("");
          toast.success("Message Sent Successfully");
          // onSubmit(values);
          // reset();
        })
        .catch((err) => toast.error("Please enter value"));
    }
  };
  return (
    <div>
      <Sidebar />
      <ToastContainer />
      <main className='s-layout__content'>
        <div style={{ width: "100%" }}>
          <section>
            <div className='contact-banner-one'>
              <div className='d-flex align-items-center'>
                <div>
                  <div className='contact-text'>
                    <h1 className='text-center'>Book An Appointment</h1>
                    <p className='text-center'>
                      If you’re a current client and would like to book an
                      appointment, or if you <br />
                      would like to apply to become a client, please contact us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='book-banner'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12 col-lg-6 pr-5 padding-right-remove'>
                  <div className='book-text'>
                    <h1>Book Appointment</h1>
                    <div className='border-top-cus margin-left'></div>
                    <FormGroup className='mt-2'>
                      <Input
                        type='name'
                        name='name '
                        id='exampleEmail'
                        placeholder='Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <span
                        style={{
                          color: "red",

                          top: "5px",
                          fontSize: "10px",
                        }}
                      >
                        {errors["name"]}
                      </span>
                    </FormGroup>
                    <FormGroup className='mt-2'>
                      <Input
                        type='email'
                        name='email '
                        id='exampleEmail'
                        placeholder='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span
                        style={{
                          color: "red",

                          top: "5px",
                          fontSize: "10px",
                        }}
                      >
                        {errors["email"]}
                      </span>
                    </FormGroup>
                    <FormGroup className='mt-2'>
                      <Input
                        type='textarea'
                        name='message'
                        rows='4'
                        id='exampleText'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <span
                        style={{
                          color: "red",

                          top: "5px",
                          fontSize: "10px",
                        }}
                      >
                        {errors["message"]}
                      </span>
                    </FormGroup>
                    <div className='d-flex contact-footer'>
                      <p className='pl-3'>
                        By submitting your phone number, you agree to receiving
                        texts from NEMT Solutions LLC.
                      </p>
                    </div>

                    <button
                      className='book-btn-style text-center mt-2'
                      onClick={() => submitHandler()}
                    >
                      Book an appointment
                    </button>
                  </div>
                </div>
                <div className='col-md-12 col-lg-6'>
                  <div className='book-text'>
                    <h1>Contact Info</h1>
                    <div className='border-top-cus margin-left'></div>
                    <div className='d-flex contact-footer'>
                      <i class='fas fa-home'></i>
                      <p className='pl-3'>
                        24167 Cruise Circle Drive Canyon Lake, CA, 92587
                      </p>
                    </div>
                    <div className='d-flex contact-footer pt-3'>
                      <i class='fas fa-phone-alt'></i>
                      <p className='pl-3'>(888) 642-2055</p>
                    </div>
                    <div className='d-flex contact-footer pt-3'>
                      <i class='far fa-envelope'></i>
                      <p className='pl-3'>info@nemtsolutions.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='pt-5'>
            <div className='container-fluid'>
              <div className='copy-right'>
                <p>
                  <span> © Copyright 2012 - 2024 </span>
                  <span className='pl-2'>| NEMT Solutions </span>
                  <span className='blue-color pl-2'>| All Rights Reserved</span>
                </p>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
