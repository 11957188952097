import React from "react";
import { NavLink } from "react-router-dom";
import "./home.css";
export default function Sidebar() {
  return (
    <div>
      <div className='s-layout__sidebar'>
        <a className='s-sidebar__trigger' href='#0'>
          <i className='fas fa-bars'></i>
        </a>

        <nav className='s-sidebar__nav'>
          <div className='nemt-logo d-flex justify-content-center pt-5  '>
            <img src={require("../../Images/nemt.png")} />
          </div>
          <div className='sidebar-menu text-center'>
            <NavLink to='/signin' className='text-center pt-4'>
              Home
            </NavLink>
            <NavLink to='/signin/dispatching' className='text-center pt-4'>
              Dispatching Software
            </NavLink>
            <NavLink to='/signin/contactus' className='text-center pt-4'>
              Contact Us
            </NavLink>
            <NavLink to='/privacy-policy' className='text-center pt-4'>
              Privacy Policy
            </NavLink>
          </div>
          <div className='sidebar-menu text-center pt-3'>
            <a href='#' className='text-center pt-5'>
              Opening Hours
            </a>
          </div>
          <div className='opening-hour pt-3'>
            <p className='font-size-16 text-center'> Monday - Saturday </p>
            <p className='font-size-16 text-center pt-2'>8:30 AM - 6:00 PM</p>
          </div>
        </nav>
      </div>
    </div>
  );
}
